import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

const mapStyles = {
  width: '100%',
  height: '100%'
};

export class MapContainer extends Component {
  render() {
    return (
      <Map
        google={this.props.google}
        zoom={14}
        style={mapStyles}
        initialCenter={{
          lat: 43.61032306893391,
          lng: 1.450583939975659
        }}
        // styles={[
        //   {
        //     elementType: 'geometry',
        //     stylers: [
        //       { color: '#ebe9e8' },
        //       {
        //         elementType: 'labels.text.fill',
        //         stylers: [{ color: '#fa1105' }]
        //       }
        //     ]
        //   },
        //   {
        //     elementType: 'labels.text.stroke',
        //     stylers: [{ color: '#242f3e' }]
        //   },

        //   {
        //     elementType: 'labels.text.stroke',
        //     stylers: [{ color: '#eaf1fb' }]
        //   },
        //   {
        //     featureType: 'landscape.natural',
        //     elementType: 'geometry',
        //     stylers: [{ color: '#ebe9e8' }]
        //   },
        //   {
        //     featureType: 'poi',
        //     elementType: 'geometry',
        //     stylers: [{ color: '#ebe9e8' }]
        //   },
        //   {
        //     featureType: 'poi',
        //     elementType: 'labels.text.fill',
        //     stylers: [{ color: '#9c9a9a' }]
        //   },
        //   {
        //     featureType: 'road',
        //     elementType: 'geometry',
        //     stylers: [{ color: '#ffffff' }]
        //   },
        //   {
        //     featureType: 'road',
        //     elementType: 'labels.text.fill',
        //     stylers: [{ color: '#ffffff' }]
        //   },

        //   {
        //     featureType: 'road.arterial',
        //     elementType: 'geometry',
        //     stylers: [{ color: '#ffffff' }]
        //   },
        //   {
        //     featureType: 'road.highway',
        //     elementType: 'geometry',
        //     stylers: [{ color: '#bfbdbd' }]
        //   },
        //   {
        //     featureType: 'road.highway',
        //     elementType: 'geometry.stroke',
        //     stylers: [{ color: '#bfbdbd' }]
        //   },
        //   {
        //     featureType: 'road.highway',
        //     elementType: 'labels.text.fill',
        //     stylers: [{ color: '#bfbdbd' }]
        //   },
        //   {
        //     featureType: 'road.highway.controlled_access',
        //     elementType: 'geometry',
        //     stylers: [{ color: '#c7c3c3' }]
        //   },
        //   {
        //     featureType: 'road.highway.controlled_access',
        //     elementType: 'geometry.stroke',
        //     stylers: [{ color: '#9c9a9a' }]
        //   },
        //   {
        //     featureType: 'road.highway.controlled_access',
        //     elementType: 'geometry.stroke',
        //     stylers: [{ color: '#9c9a9a' }]
        //   },
        //   {
        //     featureType: 'transit',
        //     elementType: 'geometry',
        //     stylers: [{ color: '#9c9a9a' }]
        //   },
        //   {
        //     featureType: 'water',
        //     elementType: 'geometry.fill',
        //     stylers: [{ color: '#bfc8de' }]
        //   },
        //   {
        //     featureType: 'water',
        //     elementType: 'labels.text.fill',
        //     stylers: [{ color: '#bfc8de' }]
        //   }
        // ]}
        styles={[
          {
            featureType: 'all',
            elementType: 'all',
            stylers: [
              {
                hue: '#2D3776'
              }
            ]
          },
          {
            featureType: 'poi',
            elementType: 'all',
            stylers: [
              {
                visibility: 'off'
              }
            ]
          },
          {
            featureType: 'road',
            elementType: 'all',
            stylers: [
              {
                saturation: '0'
              },
              {
                lightness: '0'
              }
            ]
          },
          {
            featureType: 'transit',
            elementType: 'all',
            stylers: [
              {
                visibility: 'off'
              }
            ]
          },
          {
            featureType: 'water',
            elementType: 'all',
            stylers: [
              {
                visibility: 'simplified'
              },
              {
                saturation: '-60'
              },
              {
                lightness: '-20'
              }
            ]
          }
        ]}
      >
        <Marker
          title="Strateg.in"
          name="Strateg.in"
          position={{ lat: 43.61032306893391, lng: 1.450583939975659 }}
        />
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDn5GDdyEsPbNjy6g1A-cS4CQq-EYK6niE'
})(MapContainer);
