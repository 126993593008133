import React from 'react';
import { Button, Row, Col, Icon } from 'antd';
import MapContainer from '../Map/MyMap';
import Address from '../../assets/images/icons/location_icon_new.png';
import Network from '../../assets/images/icons/icon_contact_new_noir.png';
import Phone from '../../assets/images/icons/phone_icon_new_2.png';
import '../../CSS/contact.css';
import { useMediaQuery } from 'react-responsive';
import WrapperFormContact from './formContact';

const Contact = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });

  const IconFont = Icon.createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js'
  });

  return (
    <div
      style={{ backgroundColor: '#fdfdfd', position: 'relative', zIndex: 3 }}
      id="contact-us"
    >
      <div style={{ backgroundColor: '#fdfdfd', height: 1 }}></div>
      <h1 id="offre_barre">Contactez-nous </h1>
      <p className="center-para">
        Nous sommes sympas, et disponibles pour discuter de vos projets.
      </p>
      {!isMobile ? (
        <Row justify="space-around" className="row-contact">
          <Col md={12} lg={8} id="col-contact">
            <img
              src={Phone}
              alt="strateg.in number"
              style={{ objectFit: 'contain', width: 87, height: 87 }}
            />
            <br />
            <a href="tel:+33561811879">+33(0)5 61 81 18 79</a>
            <br />
            <a href="mailto:contact@strateg.in">
              <span type="link" style={{ fontSize: 16, color: '#3242a8' }}>
                {' '}
                contact@strateg.in
              </span>
            </a>
          </Col>
          <Col md={12} lg={8} id="col-contact">
            <img
              src={Address}
              alt="strateg.in adresse"
              style={{ objectFit: 'contain', width: 87, height: 87 }}
            />
            <p>
              54 rue Bayard <br /> 31000 Toulouse.
            </p>
          </Col>
          <Col md={24} lg={8} id="col-contact">
            <img
              src={Network}
              alt="strateg.in reseaux sociaux"
              style={{ objectFit: 'contain', width: 87, height: 87 }}
            />
            <div className="icons-list">
              <a href="https://facebook.com/strateginofficiel/" target="_blank">
                <IconFont type="icon-facebook" id="icon-social" />
              </a>
              <a
                href="https://www.linkedin.com/company/strateg-in-company/"
                target="_blank"
              >
                <Icon type="linkedin" id="icon-social" />
              </a>
              <a href="https://twitter.com/Strateg_in" target="_blank">
                <IconFont type="icon-twitter" id="icon-social" />
              </a>
              <a href="https://github.com/strategin" target="_blank">
                <IconFont type="icon-github" id="icon-social" />
              </a>
            </div>
          </Col>
        </Row>
      ) : null}
      <WrapperFormContact />
      <Row style={{ height: '600px' }}>
        <MapContainer />
      </Row>
    </div>
  );
};

export default Contact;
